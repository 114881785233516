<template>
    <div class="main">
        <div v-html="regulationsText" class="regulationsText"></div>
    </div>
</template>

<script>
export default {
    components:{
        
    },
    data(){
        return{
            regulationsText:`
            <h1 style="text-align: center;"><strong>医键通隐私协议</strong></h1>
            <p style="text-align: right;">更新日期：2021年12月</p>
            <p>医键通后勤管理平台是由广州医键通软件开发有限公司（下称&ldquo;医键通&rdquo;或&ldquo;我们&rdquo;）向企业/组织（&ldquo;您&rdquo;或&ldquo;用户&rdquo;）提供配套的智慧后勤服务管理平台，我们尊重并保护个人信息，在您访问和使用医键通服务时，我们将按照本隐私协议（以下简称&ldquo;本协议&rdquo;）收集、处理及披露您的信息。</p>
            <p><strong><u>您注册、登录医键通后勤管理平台和/或使用医键通提供的服务，即表示您同意接受服务条款，如您不同意该协议，请不要使用本服务。因此，敬请仔细阅读。</u></strong></p>
            <p><strong>1.本协议适用范围</strong></p>
            <p>医键通后勤管理平台向企业/组织提供智慧后勤解决方案，产品覆盖后勤管理中对输送、维修、巡查、巡检、保安巡逻、医疗废物管理、考勤打卡、被服、智慧厕所管理、停车场管理等方面，支持多种后勤服务按需组合，医键通后勤管理平台包含域名为eai9.com、ektlang.com的网站以及泰科医键通APP、泰科后勤服务微信公众号、微信小程序等；当您使用我们的任何产品和服务时，本协议即适用，无论该产品和服务是否单独设置了隐私协议，也无论您是浏览用户（访客）还是注册登录用户。</p>
            <p><strong>2我们如何收集和使用您的个人信息</strong></p>
            <p><strong>一、我们如何收集您的个人信息的情形</strong></p>
            <p>为了向企业/组织提供医键通后勤管理平台服务，改进及优化我们的服务体验并保障您的帐号安全我们会收集如下您使用医键通后勤管理平台以及使用方式的信息，并将这些信息进行关联：</p>
            <p>2.1.注册信息</p>
            <p>当您在我们网站创建或登录账户时，您须向我们提供会员名、所属单位，确认您的登录密码，提供您在中华人民共和国境内手机号码。您提交的手机号码用于您注册、登录、绑定账户、密码找回时接受验证码，并且作为您与医键通指定的联系方式之一，用来接受相关业务通知（如微信/app/短信消息提醒、服务变更等）。</p>
            <p>2.2.在您使用服务过程中我们会收集以下的信息：</p>
            <p>2.2.1.设备信息：</p>
            <p>a.为了保障软件与服务的安全运行、运营的质量及效率和第三方服务需要，我们会收集您的硬件型号、操作系统版本号、设备标识符（Android如IMEI、AndroidID、IMSI、ICCID、MEID、硬件序列号，IOS如IDFV、IDFA；不同的标识符在有效期、是否可以由用户重置以及获取方式方面会有所不同）、SD卡数据、网络设备硬件地址（MAC地址）、IP地址、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志、设备传感器数据。我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置、唯一设备标识符等软硬件特征信息）、设备所在位置相关信息（例如IP地址、GPS位置以及能够提供相关信息的Wi-Fi接入点、蓝牙和基站等传感器信息）。我们可能会将前面两类信息进行关联，以便我们能在不同设备上为您提供一致的服务；</p>
            <p>b.为了实现第三方登录和预防恶意程序、保障运营质量及效率，我们会获取您的软件安装列表、收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。</p>
            <p>c.为了实现订单分享、识别功能，我们可能会调用剪切板对相关的信息（口令、订单等）进行识别，请您放心，我们不会收集您的隐私信息。</p>
            <p>2.2.2.日志信息：当您使用我们的网站或客户端提供的服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、IP地址、浏览器的类型、电信运营商、使用的语⾔、访问⽇期和时间及您访问的网页记录等；</p>
            <p>2.2.3.用户账户的支持信息：基于您使用医键通服务⽽产生的用户的操作记录、报障记录和针对用户故障的排障过程（如通信或通话记录），医键通将通过记录，分析这些信息以便及时响应您的帮助请求和改进服务；</p>
            <p>2.2.4.录音、录像、照片信息：您使用我们的特定功能（例如视频会议、扫码、拍照等）时，在获得您的授权后，我们收集的信息。拒绝提供该信息仅会使您无法使用上述功能，但不影响您正常使用我们的产品和/或服务的其他功能。此外，您可随时关闭相关功能；</p>
            <p>2.2.5.位置信息：指您开启设备定位功能并使用我们基于位置提供的相关产品和/或服务时（如考勤打卡等），我们收集的有关您位置的信息，包括：您通过具有定位功能的移动设备使用我们的产品和/或服务时，我们通过GPS或WiFi等方式收集的您的地理位置信息。您可以通过关闭定位功能随时停止我们对您的地理位置信息的收集，但之后您可能将无法使用相关服务或功能，或者无法达到相关服务拟达到的效果；</p>
            <p>2.2.6.付费相关信息：为帮助您顺利完成医键通云平台服务的试用、购买支付、退款，并在服务期间持续为您提供客服、技术支持等服务，医键通会要求您提供完整的联系方式及付、退款账户等信息；</p>
            <p>2.2.7.我们在向您服务时，我们会按照本协议的约定收集、使用、存储、对外提供及保护您的个人信息；超出本协议约定收集您的个人信息的，我们会另行向您说明信息收集的范围与目的，并征得您的同意后方收集提供相应服务所必要的您的信息；如您选择不提供前述信息，将会影响到您使用相应服务。</p>
            <p>2.3.其他方分享的您的信息</p>
            <p>2.3.1.若您以其他方式关联登录、使用我们的服务，我们会向第三方请求您的个人信息，对于我们需要但第三方无法提供的个人信息，我们仍会要求您提供。如果您拒绝提供，将可能导致您无法正常使用我们的产品和/或服务的某些功能；如果您同意提供，此时，您将授权我们读取您在该第三方账号注册的相关信息（如昵称、头像等）；</p>
            <p>2.3.2.我们将会依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律法规规定的前提下，使用从第三方获得的您的个人信息。</p>
            <p>2.4.我们希望提供给您的产品和/或服务是完善的，所以我们会不断改进我们的产品和/或服务，包括技术。这意味着我们的产品和/或服务可能会经常推出新功能，可能需要收集新的信息。如果我们收集的个人信息或使用方式出现重大变化或调整，我们会通过适当的方式通知您并且可能会修订本协议</p>
            <p><strong>二、我们使用您的个人信息的情形</strong></p>
            <p>2.5.我们出于如下目的，使用您提交以及我们收集的个人信息：</p>
            <p>2.5.1.为了向您提供服务，我们会向您发送信息、通知或与您进行业务沟通，包括但不限于为保证服务完成所必须的验证码、使用服务时所必要的推送通知；</p>
            <p>2.5.2.用于数据分析，以便向您提供更加优质的产品和/或服务；我们更加了解您如何接入和使用我们的产品和/或服务，从而针对性地回应您的个性化需求，例如位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应；</p>
            <p>2.5.3.我们可能以个人信息统计数据为基础，设计、开发、推广全新的产品及服务；我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，但这些统计信息不包含您的任何身份识别信息；</p>
            <p>2.5.4.软件认证或管理软件升级；</p>
            <p>2.5.5.让您参与有关我们产品和服务或通过我们的产品和服务发起的调查，是否参与调查将由您全权决定，并且由您自行选择提供哪些信息；</p>
            <p>2.5.6.出于安全、合法调查等目的，我们可能使用您的数据做数据汇总、分析、挖掘（包括商业化利用），但这些信息都采用匿名的方式，不能识别您的身份。</p>
            <p>2.6如超出收集个人信息时所声称的目的，或者在超出具有直接或合理关联的范围使用个人信息前，我们会再次向您告知并征得您的明示同意。</p>
            <p>2.7.其他</p>
            <p>您理解并同意，如我们未在上述场景中明示您需要收集的个人信息，我们将会通过页面提示、交互设计等方式另行向您明示信息收集的内容、范围和目的并征得您同意。</p>
            <p><strong>3我们如何使用&nbsp;Cookie&nbsp;和同类技术</strong></p>
            <p>3.1.为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。医键通只能读取医键通提供的Cookies。</p>
            <p>3.2.我们不会将Cookie用于本协议所述目的之外的任何用途。您可根据自己的偏好管理Cookie，您也可以清除计算机上保存的所有Cookie。大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。</p>
            <p><strong>4.我们如何共享、转让、公开披露您的信息</strong></p>
            <p>4.1.共享</p>
            <p>我们不会与其他组织和个人共享您的个人信息，但以下情况除外：</p>
            <p>4.1.1.在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息；</p>
            <p>4.1.2.在法定情形下的共享：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息；</p>
            <p>4.1.3.与关联公司间共享：为识别帐号异常，保护关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息，如果关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。</p>
            <p>4.2.转让</p>
            <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
            <p>4.2.1.在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；4.2.2.在医键通与其他法律主体者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本协议的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。</p>
            <p>4.3.公开披露</p>
            <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
            <p>4.3.1.获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；</p>
            <p>4.3.2.如果我们确定您出现违反法律法规或严重违反医键通相关协定规则的情况，或为保护医键通后勤管理平台服务及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或医键通相关协定规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及医键通已对您采取的措施。</p>
            <p>4.4.您主动与他人共享的信息</p>
            <p>4.5.共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
            <p>以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</p>
            <p>1、与国家安全、国防安全有关的；</p>
            <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
            <p>3、与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</p>
            <p>4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
            <p>5、您自行向社会公众公开的个人信息；</p>
            <p>6、从合法公开披露的信息中收集个人信息的，如合法的新闻报导、政府信息公开等管道。</p>
            <p>请您注意，根据法律规定，共享、转让经匿名化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</p>
            <p><strong>5.我们如何保护和保存您的个人信息</strong></p>
            <p>5.1.医键通非常重视您的信息安全。我们努⼒采取各种合理的物理、电子和管理方面的安全措施来保护您的个人信息。防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会使用加密技术提⾼个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽⼒确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
            <p>5.2.我们会采取合理可行的措施，尽⼒避免收集无关的个人信息。我们只会在达成本协议所述目的所需的期限内保留您的个人信息，除非受到法律的允许。超出上述个人信息保存期限后，我们会对您的个人信息进行删除或匿名化处理。</p>
            <p>5.3.请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码等个人信息透露给他人。</p>
            <p>5.4.在不幸发生个人信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低⻛险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部⻔要求，上报个人信息安全事件的处置情况。</p>
            <p>5.5.互联网环境并非百分之百安全，尽管我们有这些安全措施，但请注意在互联网上不存在&ldquo;完善的安全措施&rdquo;，我们将基于正常的商业努力确保您的信息的安全。</p>
            <p><strong>6.本政策如何更新</strong></p>
            <p>我们的隐私政策可能变更。未经您明确同意我们不会限制您按照本隐私政策所应享有的权利。对于本政策的重大变更，我们会提供显著的通知（包括公告通知甚至向您提供弹窗提示）。</p>
            <p>本政策所指的重大变更包括但不限于：</p>
            <p>1、我们的服务模式发生重大变化。如处理个人信息的目的、处理个人信息的类型以及个人信息的使用方式等；</p>
            <p>2、我们在控制权、组织架构等方面发生重大变化。如并购重组等引起的控制者变更等；</p>
            <p>3、个人信息共享、转让或公开披露的主要对象发生变化；</p>
            <p>4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
            <p>5、我们负责处理使用者信息安全的责任部门、联络方式及投诉管道发生变化时；</p>
            <p>6、使用者信息安全影响评估报告表明存在高风险时。</p>
            <p><strong>7、账户注销</strong></p>
            <p>您可以在客户端通过【我的】-【账号设置】-【注销账号】进行帐号注销。在您注销帐号前，我们将验证您已授权给我们的个人身份、安全状态、设备信息等。我们将会在收到您的申请后及时进行处理，并在15个工作日或法律法规要求的时限内回复您的请求。您知悉并理解，注销帐号的行为是不可逆的行为，当您注销帐号后，我们将删除有关您的相关信息，若删除个人信息从技术上难以实现的，我们会进行匿名化处理，或停止除存储和采取必要的安全保护措施之外的处理，但法律法规另有规定的除外。</p>
            <p><strong>8、如何联系我们</strong></p>
            <p>如果您要查询、更正、补充您的信息，或者对医键通后勤管理平台的隐私保护政策或数据处理有任何疑问、意见或建议，您可通过我们app或微信提交意见建议或发送邮件swei_liu@ektlang.com与我们联系，此电子邮件地址作为本服务条款的组成部分可能会不时进行更新。我们将在15天之内响应您的请求。</p>`
            
        }
    }
}
</script>

<style lang="less" scoped>
.main{
    padding: 2rem 1rem 2rem 1rem;
}
</style>